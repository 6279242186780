import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class CustomLoaderComponent implements OnInit {

  backgroundColor = 'bg-primary-500';
  loaderItems = 12;

  ngOnInit(): void {
    this.getBackgroundColor();
  }

  getBackgroundColor(): string {
    return `after:${this.backgroundColor}`;
  }
}
